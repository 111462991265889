import {
  Stack,
  Typography,
  Modal,
  Checkbox,
  Box,
  InputLabel,
  Button,
} from '@mui/material';
import { Alert } from '@mui/lab';
import { useState, memo, useEffect } from 'react';

import BaseInput from 'components/ui/BaseInput/BaseInput';
import BasePhoneInput from 'components/ui/BasePhoneInput/BasePhoneInput';
import { matchIsValidTel } from 'mui-tel-input';
import { sendContactData } from 'services/api';
import { setUserContactInfoStatus } from 'redux/UserSlice/UserSlice';
import { useAppDispatch } from 'redux/hooks';

const ContactModal = ({ contactStatus }: { contactStatus: boolean }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>('');
  const [tgNickname, setTgNickname] = useState<string>('');
  const [error, setError] = useState<string>('');
  const dispatch = useAppDispatch();
  const [marketingApprove, setMarketingApprove] = useState<boolean>(true);

  useEffect(() => {
    if (!contactStatus) {
      setIsModalOpen(true);
    }
  }, [contactStatus]);

  const handleContactFilled = () => {
    if (!phone && !tgNickname) {
      setError('Заполните хотя бы 1 поле');
      return;
    }

    if (!matchIsValidTel(phone)) {
      setError('Некорректный номер телефона');
      return;
    }

    sendContactData({
      phone,
      tgNickname,
      marketingApprove
    }).finally(() => {
      setIsModalOpen(false);
      dispatch(setUserContactInfoStatus(true));
    });
  };

  // Валидация на номер
  // хотя бы 1 поле - обязательное
  return (
    <Modal
      onClose={(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setIsModalOpen(false);
      }}
      open={isModalOpen}
      disableEscapeKeyDown={true}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '310px',
          maxHeight: '100vh',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          p: 2,
          overflow: 'auto',
        }}
      >
        <Stack alignItems={'flex-start'}>
          <Typography variant='h4'>Уточнение контактных данных</Typography>
          <Typography>
            Чтобы улучшить наш сервис, давайте заполним некоторые дополнительные
            данные о вас
          </Typography>
          <BasePhoneInput
            id='phone'
            name='phone'
            hiddenLabel
            variant='filled'
            onlyCountries={['RU', 'KZ', 'BY', 'UA']}
            langOfCountryName='ru'
            defaultCountry='RU'
            onChange={(phone: string) => setPhone(phone)}
            value={phone}
            fullWidth
            inputProps={{
              autoComplete: 'off',
            }}
            sx={{
              my: '10px',
            }}
          />
          <BaseInput
            fullWidth
            value={tgNickname}
            label={'Ник в телеграм'}
            placeholder='@nickname'
            onChange={(evt) => setTgNickname(evt.target.value)}
            sx={{
              mb: '10px',
            }}
          />
          <InputLabel
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Checkbox
              title='Даю согласие на получение маркетинговой информации'
              value={marketingApprove}
              checked={marketingApprove}
              onChange={() => setMarketingApprove((prev) => !prev)}
            />
            <Typography
              sx={{
                textWrap: 'wrap',
              }}
            >
              Даю согласие на получение маркетинговой информации
            </Typography>
          </InputLabel>
          {error && (
            <Alert
              sx={{
                width: '100%',
                mt: '10px',
              }}
              severity='error'
            >
              {error}
            </Alert>
          )}
          <Button
            fullWidth
            onClick={handleContactFilled}
            variant='contained'
            color='primary'
            sx={{ mt: '10px' }}
          >
            Сохранить
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default memo(ContactModal);
